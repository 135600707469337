export default class Utils {
    static swiperWrapper(
        selector,
        childElements = '*',
        classesModifier = '',
        customParent = '<div class="swiper-wrapper"></div>',
    ) {
        $(`${selector} > ${childElements}`)
            .wrapAll(`${customParent}`)
            .addClass(`swiper-slide ${classesModifier}`)
    }

    static toggleIsActiveClass(selector, event = 'click') {
        $(selector).on(event, function () {
            $(`${selector}.is-active`).removeClass('is-active')
            $(this).addClass('is-active')
        })
    }

    static isAnimable() {
        return !$('body.eco-mode-active').length && Utils.isBreakpoint('(min-width: 1000.1px)')
    }

    static isBreakpoint(breakpoint) {
        return window.matchMedia(breakpoint).matches
    }

    static isEcomodeActive() {
        return $('body').hasClass('eco-mode-active')
    }

    static scrollRevealWithIntersectObserver() {
        const ratio = 0.2
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        }

        const handleIntersect = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio >= ratio) {
                    entry.target.classList.remove('reveal')
                    observer.unobserve(entry.target)
                }
            })
        }

        document.documentElement.classList.add('reveal-loaded')
        const observer = new IntersectionObserver(handleIntersect, options)
        document.querySelectorAll('.reveal').forEach((el) => {
            observer.observe(el)
        })
    }
}
