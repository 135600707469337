/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import NavbarEnum from './enumerators/navbar.enum'
import HomeService from './services/home.service'

document.addEventListener('DOMContentLoaded', () => {
    new HomeService()
    if ($(NavbarEnum.ECOMODE).length) {
        import(
            '@scripts/services/home-slider.service' /* webpackChunkName: "scripts/home-slider.service" */
        ).then(({ default: HomeSliderService }) => {
            new HomeSliderService()
        })
    }
})
