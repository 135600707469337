import Utils from './utils.service'

export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            this.sectionPaleontologistMoveButton()
        }
    }

    sectionPaleontologistMoveButton() {
        const buttonSelector = '.js-fascinating-story-button'
        const target = '.js-fascinating-story-content'

        if (Utils.isBreakpoint('(min-width: 1000.1px)')) {
            $(target).append($(buttonSelector))
        }
    }
}
